// https://developer.mozilla.org/en-US/docs/Web/API/AudioContext
// Using AudioContext to work around iOS Safari not allowing volume control

// import appConfig from '../appConfig'

export const defaultSoundTrackVolume = 0.40

export const audio = {
    context: null,
    source: null,
    gainNode: null,
    element: null,
    easing: false,
}

export async function setupAudioContext(audioElement) {
  audio.element = audioElement

  // !IMPORTANT: note that this should only be called once.
  // These elements need to be cleaned up and disconnected cleanly if to be reused
  audio.context = new (window.AudioContext || window.webkitAudioContext)()
  audio.source = audio.context.createMediaElementSource(audioElement)
  audio.gainNode = audio.context.createGain()
  audio.source.connect(audio.gainNode)
  audio.gainNode.connect(audio.context.destination)
}

export function setVolume(newVolume) {
  if (isNaN(newVolume)) return

  // console.log('setVolume='+newVolume)

  if (audio.gainNode) {
    audio.gainNode.gain.value = newVolume
  }

  if (audio.element) {
    audio.element.volume = newVolume
  }
}

export function getVolume() {
  if (audio.gainNode) {
    return audio.gainNode.gain.value
  } else if (audio.element) {
    return audio.element.volume
  }
}

function swing(p) {
  return 0.5 - Math.cos(p * Math.PI) / 2
}

export async function rampVolume(newVolume, duration=100) {
  if (audio.easing) return
  audio.easing = true

  const easing = swing
  const interval = 12

  const originalVolume = getVolume()
  const delta = newVolume - originalVolume
  if (!delta || !duration || !easing || !interval) {
    setVolume(newVolume)
    audio.easing = false
    return Promise.resolve()
  }
  const ticks = Math.floor(duration / interval)
  var tick = 1
  return new Promise((resolve) => {
      const timer = setInterval(() => {
        setVolume(originalVolume + (
              easing(tick / ticks) * delta
          ))
          if (++tick >= ticks) {
              audio.easing = false
              clearInterval(timer)
              resolve()
          }
      }, interval)
  })
}

