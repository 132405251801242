<template lang="pug">
div.video_record.fill
  div#ask_title
    span.asked_by {{ askedByText }}
    br
    span {{ currentAskText }}
  div.video_controls
    div.loading(v-show="!hasRecording && loading")
      img(v-bind:src="loadingSrc")
    div.wrapper(v-show="hasRecording || !loading")
      img.start(v-show="!isRecording" v-on:click="startRecording" ref="start" style="touch-action: manipulation" :src="recordStartButtonSrc" :title="recordButtonTitle")
      div.stop_recording_container(v-show="isRecording || hasRecording" v-on:click="stopRecording" ref="stop" style="touch-action: manipulation")
        div#countdown_container(v-show="showTimer")
          CountdownTimer(
            :startTime="recordingLimit"
            v-bind:run="runTimer"
            v-on:timerEnd="timerEnd"
          )
        img.stop(:src="stopButtonSrc" :title="stopButtonTitle")
      img.next(v-show="hasRecording" v-on:click="saveRecording" ref="save" style="touch-action: manipulation" :src="nextButtonSrc" :title="nextButtonTitle")
      img#toggle_mute.mute(v-show="hasRecording" v-on:click="toggleMute" ref="save" style="touch-action: manipulation" :src="muteButtonSrc" :title="muteButtonTitle")
      div.link_skip
        a(v-show="hasVideo"  style="touch-action: manipulation" v-on:click="nextAsk") Skip
  video.playback(
        autoplay
        playsinline
        webkit-playsinline
        loop
        muted
        )
  audio.playback(autoplay
        playsinline
        loop
        )
  div#preview.video_preview_container(autoplay
        playsinline
        )
</template>

<script>

import { ADD_PARTICIPANT_VIDEO_MUTATION, ADD_PARTICIPANT_VIDEO_FROM_TWILIO_MUTATION } from '../graphql/mutations'
import { GET_PARTICIPANT_FROM_SLUG_QUERY, GET_TWILIO_ACCESS_TOKEN, GET_TWILIO_ROOM_RECORDINGS } from '../graphql/queries'
import { NEW_ROOM_MEDIA_SUBSCRIPTION } from '../graphql/subscriptions'
import CountdownTimer from '../components/CountdownTimer'
import config from '../appConfig'

const { connect, createLocalTracks } = require('twilio-video')

export default {
  name: 'AddParticipantVideoTwilio',
  props: {
    participant: Object,
    slug: String
  },
  components: {
    CountdownTimer,
  },
  computed: {
    recordButtonTitle () {
      if (this.hasRecording) { return "Re-Record" }
      return "Record"
    },
    stopButtonTitle () {
      if (this.hasRecording) { return "Pause playback" }
      return "Stop recording"
    },
    nextButtonTitle () {
      return "Save recording and go to next step"
    },
    nextButtonSrc () {
      return process.env.BASE_URL + "video_save_button.svg"
    },
    muteButtonSrc () {
      return this.videoMuted ? process.env.BASE_URL + "mute_off_button.svg" : process.env.BASE_URL + "mute_on_button.svg"
    },
    muteButtonTitle () {
      return this.videoMuted ? "Unmute" : "Mute"
    },
    recordStartButtonSrc () {
      return process.env.BASE_URL + "record_start_button.svg"
    },
    stopButtonSrc () {
      if (this.isRecording) {
        return process.env.BASE_URL + "record_stop_button.svg"
      } else if (this.hasRecording) {
        const video = document.querySelector('video#playback')
        if (video.paused) {
          return process.env.BASE_URL + "playback_stop_button.svg"
        }

      }
      return process.env.BASE_URL + "playback_stop_button.svg"
    },
    loadingSrc () {
      return process.env.BASE_URL + "loading.gif"
    },
    recordingLimit () {
      return config.recordLimitInSecs
    },
    currentProject () {
      return this.$store.state.currentProject
    },
    currentUser () {
      return this.$store.state.user.user
    },
    projectAsks () {
      return this.currentParticipant.project.asks
    },
    askCount () {
      return this.projectAsks.length
    },
    currentAsk () {
      return this.projectAsks[this.currentAskIndex]
    },
    currentAskText () {
      if (!this.currentParticipant) return ""
      return this.currentAsk.text
    },
    askedByText () {
      if (!this.currentParticipant) return ""
      return this.currentParticipant.project.user.firstName + " asks you to..."
    },
    hasRecording () {
      if (!this.twilioRecording) return false
      return true
    },
    hasVideo () {
      if (!this.currentParticipant?.videos || this.currentParticipant?.videos.length <= 0 || !this.currentAsk) return false

      return this.currentParticipant?.videos.find((video) => {
        return parseInt(video.askId) == parseInt(this.currentAsk.id)
      }) != undefined
    },
  },
  watch: {
    participant: function () {
      // this.$store.commit('setProject', this.participant.project)
    },
    twilioAccessToken: function () {
      // this.connectToTwilio()
    },
    hasRecording () {
      if (!this.hasRecording) return
      this.playRecording()
    },
  },
  data () {
    return {
      videoMuted: true,
      showTimer: false,
      runTimer: false,
      isRecording: false,
      loading: false,
      currentAskIndex: 0,
      currentParticipant: this.participant,
      twilioAccessToken: null,
      twilioRoom: null,
      lastTwilioRoomSid: null,
      lastParticipantSid: null,
      localTracks: null,
      twilioRecording: null,
      mediaConstraints: config.mediaDeviceConstraints,
    }
  },
  apollo: {
    twilioAccessToken () {
      return {
        query: GET_TWILIO_ACCESS_TOKEN,
        variables: {
          participantSlug: this.slug
        },
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      }
    }
  },
  methods: {
    toggleMute: function() {
      var video = document.querySelector('video.playback')
      video.muted = !video.muted
      this.videoMuted = video.muted
    },
    subscribeToNewRecording: function() {
      const vue = this
      this.$apollo.subscribe({
        query: NEW_ROOM_MEDIA_SUBSCRIPTION,
        variables: {
          participantSlug: this.slug,
        },
      }).subscribe({
        next(data) {
          console.log('got new subscription')
          console.log(data)
          vue.twilioRecording = data.data.newTwilioRecording
          vue.lastTwilioRoomSid = vue.twilioRecording.roomSid
          vue.lastParticipantSid = vue.twilioRecording.participantSid
          vue.loading = false
        },
        error(error) {
          console.log(error)
        }
      })
    },
    disconnectFromTwilioRoom: function () {
      this.twilioRoom?.disconnect()
      this.twilioRoom = null
    },
    muteLocalMedia: function () {
      this.twilioRoom?.localParticipant.audioTracks.forEach(publication => {
        publication.track.disable();
      });

      this.twilioRoom?.localParticipant.videoTracks.forEach(publication => {
        publication.track.disable();
      });
    },
    unmuteLocalMedia: function () {
      this.twilioRoom?.localParticipant.videoTracks.forEach(publication => {
        publication.track.stop();
        publication.unpublish();
      });
    },
    publishLocalVideoTrack: function () {
      const videoTrack = this.localTracks.find(track => track.kind === 'video')
      // this.twilioRoom.localParticipant.unpublishTrack(videoTrack)
      this.twilioRoom.localParticipant.publishTrack(videoTrack)
    },
    connectToTwilio: async function () {
      if (!this.twilioAccessToken) return

      await connect(this.twilioAccessToken.accessToken, {
        name: this.twilioAccessToken.roomName,
        tracks: this.localTracks
      }).then(room => {
        console.log(`Connected to Room: ${room.name} ${room.sid}`)
        console.log(this.localTracks)
        this.twilioRoom = room
        this.lastTwilioRoomSid = room.sid
        this.lastParticipantSid = room.localParticipant.sid
        this.isRecording = true
        this.runTimer = true
        this.showTimer = true

        this.publishLocalVideoTrack()

        room.on('participantConnected', participant => {
          console.log(`A remote Participant connected: ${participant}`);
          participant.tracks.forEach(publication => {
          if (publication.isSubscribed) {
            const track = publication.track;
            document.getElementById('preview').appendChild(track.attach());
          }
          })

          participant.on('trackSubscribed', track => {
            document.getElementById('preview').appendChild(track.attach());
          })
        })
        room.on('disconnected', room => {
          console.log(`disconnected from room ${room}`)
          this.getTwilioRecording()
          // Detach the local media elements
          // room.localParticipant.tracks.forEach(publication => {
          //   const attachedElements = publication.track.detach();
          //   attachedElements.forEach(element => element.remove());
          // })
        })
      }, error => {
        console.error(`Unable to connect to Room: ${error.message}`);
      })
    },
    timerEnd: function () {
      this.stopRecording()
    },
    getTwilioRecording: function() {
      if (!this.lastTwilioRoomSid) return
      this.loading = true
      this.$apollo.query({
        query: GET_TWILIO_ROOM_RECORDINGS,
        variables: {
          roomSid: this.lastTwilioRoomSid,
          participantSlug: this.slug,
          participantSid: this.lastParticipantSid,
        }
      }).then((data) => {
        // console.log(data.data.twilioRecording)
        this.twilioRecording = data.data.twilioRecording
      }).catch((error) => {
        console.error(error)
        this.loading = false
      })

    },
    getTwilioAccessToken: function() {
      this.$apollo.query({
        query: GET_TWILIO_ACCESS_TOKEN,
        variables: {
          participantSlug: this.slug
        }
      }).then((data) => {
        // console.log(data.data)
        this.twilioAccessToken = data.data.twilioAccessToken
      }).catch((error) => {
        console.error(error)
      })
    },
    getParticipant: function() {
      this.$apollo.query({
        query: GET_PARTICIPANT_FROM_SLUG_QUERY,
        variables: {
          slug: this.slug
        }
      }).then((data) => {
        // console.log(data.data)
        this.currentParticipant = data.data.participant
      }).catch((error) => {
        console.error(error)
      })
    },
    getDevices: function() {
      navigator.mediaDevices.enumerateDevices().then((inputDevices) =>{
        this.inputDevices = inputDevices
      }).catch((error) => {
        console.log(error)
      })
    },
    addVideo: async function() {
      this.loading = true
      this.$apollo.mutate({
        mutation: ADD_PARTICIPANT_VIDEO_FROM_TWILIO_MUTATION,
        variables: {
          slug: this.slug,
          askId: this.currentAsk.id,
          roomSid: this.lastTwilioRoomSid,
        },
      }).then((data) => {
        console.log(data)
        // console.log(data.data.addVideo)
        this.loading = false
        this.nextAsk()
      }).catch((error) => {
        console.error(error)
        this.loading = false
      })
    },
    nextAsk: function () {
      if (this.currentAskIndex >= this.askCount - 1) {
          this.next()
        } else {
          this.resetPlayback()
          this.currentAskIndex = this.currentAskIndex + 1
        }
    },
    saveRecording: function() {
      this.addVideo()
    },
    playRecording: function() {
      if (!this.twilioRecording) return

      var video = document.querySelector("video.playback")
      video.src = this.twilioRecording.compositionUrl ? this.twilioRecording.compositionUrl : this.twilioRecording.videoUrl
      video.load()
      video.loop = true

      document.querySelector('#toggle_mute').addEventListener('click', (e) =>{
        console.log(e)
        this.toggleMute()
        video.play()
      })

      if (config.isSafari) {
        // !IMPORTANT: cannot autoplay unmuted video on safari.  It needs a user interaction.
        // video.muted = false
        video.play()
      } else {
        video.muted = false
        const playPromise = video.play()
        if (playPromise !== undefined) {
          playPromise.then(() => {
            // Automatic playback started!
          }).catch((error) => {
            // Automatic playback failed.
            // Show a UI element to let the user manually start playback.
            console.error(error)
          });
        }
      }
    },
    stopPlayback: function() {
      var video = document.querySelector("video.playback")
      var audio = document.querySelector("audio.playback")
      video.pause()
      audio.pause()
    },
    stopRecording: function() {
      if (this.hasRecording) {
        this.stopPlayback()
      }

      this.runTimer = false
      this.showTimer = false
      this.isRecording = false

      this.disconnectFromTwilioRoom()
    },
    resetPlayback: function() {
      this.twilioRecording = null
      var video = document.querySelector("video.playback")
      var audio = document.querySelector("audio.playback")
      if (video.src) {
        window.URL.revokeObjectURL(video.src)
        video.removeAttribute('src');
        video.load()
      }
      if (audio.src) {
        window.URL.revokeObjectURL(audio.src)
        audio.removeAttribute('src');
        audio.load()
      }
    },
    startRecording: function() {
      this.resetPlayback()
      this.connectToTwilio()
    },
    destroyTracks: function () {
      this.localTracks?.forEach(track => {
        const attachedElements = track.detach();
        attachedElements.forEach(element => element.remove());
      })
      this.localTracks = null
    },
    setupTracks: async function () {
      this.destroyTracks()
      await createLocalTracks(this.mediaConstraints).then((tracks) =>{
        this.localTracks = tracks
        this.setupVideoPreview()
      })
    },
    setupVideoPreview: function() {
      // console.log(this.localTracks)
      const localVideoTrack = this.localTracks.find(track => track.kind === 'video')
      document.getElementById('preview').appendChild(localVideoTrack.attach());
    },
    stopStreaming: function() {
      this.twilioRoom?.localParticipant.tracks.forEach(publication => {
        const attachedElements = publication.track.detach();
        attachedElements.forEach(element => element.remove());
      })
    },
    fileUpload: function ({ target: { files = [] } }) {
      if (!files.length) {
        return
      }

      this.loading = true
      this.$apollo.mutate({
        mutation: ADD_PARTICIPANT_VIDEO_MUTATION,
        variables: {
          slug: this.slug,
          askId: this.currentAsk.id,
          file: files[0]
        },
        context: {
          hasUpload: true
        }
      }).then((data) => {
        console.log(data)
        this.loading = false
        if (this.currentAskIndex >= this.askCount - 1) {
          this.next()
        } else {
          this.resetPlayback()
          this.currentAskIndex = this.currentAskIndex + 1
        }
      }).catch((error) => {
        console.error(error)
        this.loading = false
      })
    },
    openFullscreen: function(selector) {
      var elem = document.querySelector(selector)

      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen()
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen()
      }
    },
    allStop: function() {
      this.stopStreaming()
      this.stopRecording()
      this.resetPlayback()
    },
    next: function() {
      this.$router.push({name:"Done"})
    },
  },
  beforeRouteLeave(to, from, next) {
    this.allStop()
    next()
  },
  beforeUnmount: function() {
    this.allStop()
  },
  mounted: function () {
    window.vue = this
    // console.log(this.slug)
    // console.log(this.participant)
    if (!this.participant) { this.getParticipant() }
    this.getDevices()
    this.setupTracks()
    this.subscribeToNewRecording()
  }
}
</script>

<style>
#preview video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100
}

</style>

<style scoped>
.video_record {
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  overflow: hidden;
}


.overlay {
  display: block;
  position: absolute;
}

video.playback {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
}

.asked_by {
  font-size: 24px;
  font-weight: normal;
}

#ask_title {
  font-weight: bold;
  font-size: 40px;
  color: #ffffff;
  text-align: center;
  width: 100%;
  position: relative;
  margin-top: 20%;
  z-index: 1000;
  text-shadow: 4px 4px 6px rgba(0,0,0,0.30);

}

.fill {
  width: 100%;
  height: 100%;
}

.title {
  position: absolute;
  z-index: 500;
  top: 45%;
  width: 100%;
  color: #ffffff;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  text-shadow: 0 2px 40px rgba(0,0,0,0.45);
}

.select_input_device_container {
  position: absolute;
  z-index: 800;
  bottom: 30%;
  width: 260px;
  border: none;
  text-align: left;
}

.select_input_device {
  border: 2px solid rgba(255,255,255,0.8);
  border-radius: 10px;
  padding: 8px 12px;
  cursor: pointer;
  color: rgba(255,255,255,0.8);
  font-size: 16px;
  font-weight: bold;
  text-shadow: 0 2px 10px rgba(0,0,0,0.50);
  box-shadow: 0 2px 10px rgba(0,0,0,0.20);
  margin-bottom: 10px;
}


#countdown_container {
  position: absolute;
  z-index: 800;
  top: 0px;
  left: 9px;
  width: 80px;
  height: 80px;
}

.stop_recording_container {
  display: inline-block;
  position: relative;
}

.link_skip {
  position: absolute;
  bottom: -25px;
  width: 100%;
  text-align: center;
}
</style>
