<template lang="pug">
div.play_project
  SegmentedProgress(
    :numSegments="numSegments"
    :progress="progress"
    :currentSegment="currentSegment"
    v-on:tapped="handleProgressSegmentTapped"
  )
  div.view_container(v-bind:class="{view_container_small: isSmallScreen}")
    Header(v-on:is-small-screen="setIsSmallScreen")
  div.play_button(v-show="showPlayButton" v-on:click="togglePlayPause")
    img(:src="playIconSrc")
  div.embed-container(v-on:click="togglePlayPause")
    div#title(v-if="showAsk" v-bind:class="{title_smaller: isSmallScreen}") {{ askTitle }}
    video#play1(playsinline v-bind:class="{blur_video: showPlayButton}")
    video#play2(playsinline v-bind:class="{blur_video: showPlayButton}")
    video#video_cover_blur(playsinline muted)
    audio#soundtrack(autoplay loop crossOrigin="anonymous")
    div.black_background
</template>

<script>
/* global gtag */

import { PLAY_PROJECT_QUERY } from '../graphql/queries'
import { TRANSCODE_VIDEO_MUTATION } from '../graphql/mutations'
import Header from '../components/Header'
import SegmentedProgress from '../components/SegmentedProgress'
import { defaultSoundTrackVolume, setupAudioContext, setVolume, rampVolume } from '../lib/audio'
import config from '../appConfig'

export default {
  name: 'PlayProject',
  metaInfo() {
    return {
        title: `${this.recipientName}'s video`,
        meta: [
            { property: 'og:title', content: `${this.recipientName}'s video`},
            {name: 'robots', content: 'index,follow'}
        ]
    }
  },
  props: {
    slug: String,
    showAsk: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Header,
    SegmentedProgress,
  },
  watch: {
    videoUrls () {
      if (this.videoUrls.length <= 0) return
      this.showAsk = this.playProject.showAsk
      this.setupPlayback()
    },
  },
  computed: {
    playIconSrc () {
      return process.env.BASE_URL + "play_icon_white.svg"
    },
    recipientName () {
      return this.playProject?.recipientName ? this.playProject?.recipientName : ""
    },
    videoUrls () {
      if (!this.playProject) return []
      return this.playProject?.videoUrls
    },
    audioUrl () {
      if (!this.playProject) return ""
      return this.playProject?.audioUrl
    },
    askTitle () {
      return this.playProject?.videos[this.currentPlaybackIndex].ask.text
    },
    numSegments () {
      if (!this.playProject) return 0
      return this.playProject?.videoUrls.length
    },
    currentSegment () {
      return this.currentPlaybackIndex + 1
    }
  },
  data () {
    return {
      currentPlaybackIndex: 0,
      playProject: null,
      isSmallScreen: false,
      showPlayButton: false,
      stopPlayback: false,
      timer: null,
      progress: 0,
      isAudioSetup: false,
    }
  },
  apollo: {
    playProject () {
      return {
        // gql query
        query: PLAY_PROJECT_QUERY,
        // Static parameters
        variables () {
          return {
            slug: this.slug
          }
        },
        skip () {
          return !this.slug
        },
        fetchPolicy: 'cache-and-network',
      }
    }
  },
  methods: {
    handleProgressSegmentTapped: function(segment) {
      document.getElementById("play1").pause()
      document.getElementById("play2").pause()

      this.currentPlaybackIndex = segment - 1
      this.showPlayButton = false
      this.playNextVideo()
    },
    nextIndex: function(index) {
      return index < (this.videoUrls.length -1) ? index+1 : 0
    },
    setupPlayback: function() {
      const video1 = document.getElementById("play1")
      const video2 = document.getElementById("play2")
      const audio = document.getElementById("soundtrack")
      video1.addEventListener('ended', () => {
        this.currentPlaybackIndex = this.nextIndex(this.currentPlaybackIndex)
        this.playNextVideo()
      })
      video2.addEventListener('ended', () => {
        this.currentPlaybackIndex = this.nextIndex(this.currentPlaybackIndex)
        this.playNextVideo()
      })

      // once we have the video metadata, cover video depending on aspect ratio
      video1.onloadeddata = () => {
        // video1.style.objectFit = ((video1.videoWidth > video1.videoHeight) && (window.innerWidth > window.innerHeight)) || ((video1.videoWidth < video1.videoHeight) && (window.innerWidth < window.innerHeight)) || (video1.videoHeight == video1.videoWidth)  ? "cover" : "contain"
        video1.style.objectFit = ((video1.videoWidth < video1.videoHeight) && (window.innerWidth > window.innerHeight)) ? "contain" : "cover"
      }
      // once we have the video metadata, cover video depending on aspect ratio
      video2.onloadeddata = () => {
        // video2.style.objectFit = ((video2.videoWidth > video2.videoHeight) && (window.innerWidth > window.innerHeight)) || ((video2.videoWidth < video2.videoHeight) && (window.innerWidth < window.innerHeight)) || (video2.videoHeight == video2.videoWidth)  ? "cover" : "contain"
        video2.style.objectFit = ((video2.videoWidth < video2.videoHeight) && (window.innerWidth > window.innerHeight)) ? "contain" : "cover"
      }

      audio.src = this.audioUrl
      audio.load()
      video1.volume = 1
      video2.volume = 1
      audio.volume = 1 // 0.07  // volume does not work in  ios safari so it is now controlled in producing the audio files

      if (!config.isIOS) {
        this.isAudioSetup = true
        setupAudioContext(audio)
        setVolume(defaultSoundTrackVolume)
      }
      audio.play()

      this.playNextVideo()
      this.startProgressUpdate()

    },
    togglePlayPause: function() {
      // this will hide the playbutton if it exists.
      this.showPlayButton = false

      const video1 = document.getElementById("play1")
      const video2 = document.getElementById("play2")
      const bg_video_cover_blur = document.getElementById("video_cover_blur")
      const audio = document.getElementById("soundtrack")
      var front = video1
      if (this.currentPlaybackIndex%2 == 1) {
        front = video2
      }

      if (!this.isAudioSetup) {
        this.isAudioSetup = true
        setupAudioContext(audio)
        setVolume(defaultSoundTrackVolume)
      }

      if (front.paused) {
        front.play()
        audio.play()
        if (this.isAudioSetup) setVolume(defaultSoundTrackVolume)
        bg_video_cover_blur.play()
        this.startProgressUpdate()
        this.showPlayButton = false
        if (this.nextIndex(this.currentPlaybackIndex) == 0) this.stopPlayback = true
      } else {
        front.pause()
        audio.pause()
        bg_video_cover_blur.pause()
        this.stopProgressUpdate()
        this.showPlayButton = true
      }
    },
    playNextVideo: async function() {
      const nextIndex = this.nextIndex(this.currentPlaybackIndex)
      const video1 = document.getElementById("play1")
      const video2 = document.getElementById("play2")
      const audio = document.getElementById("soundtrack")
      const bg_video_cover_blur = document.getElementById("video_cover_blur")

      var front = video1
      var back = video2
      if (this.currentPlaybackIndex%2 == 1) {
        front = video2
        back = video1
      }

      if (front.src != this.videoUrls[this.currentPlaybackIndex]) {
        front.src = this.videoUrls[this.currentPlaybackIndex]
        front.load()
      }
      if (back.src != this.videoUrls[nextIndex]) {
        back.src = this.videoUrls[nextIndex]
        back.load()
      }

      bg_video_cover_blur.src = front.src
      bg_video_cover_blur.load()

      front.style.zIndex = "10"
      back.style.zIndex = "1"

      // cover video if its in landscape
      // front.style.objectFit = ((front.videoWidth > front.videoHeight) && (window.innerWidth > window.innerHeight)) || ((front.videoWidth < front.videoHeight) && (window.innerWidth < window.innerHeight)) || (front.videoHeight == front.videoWidth)  ? "cover" : "contain"
      front.style.objectFit =  ((front.videoWidth < front.videoHeight) && (window.innerWidth > window.innerHeight))   ? "containt" : "cover"

      if (this.stopPlayback) {
        // we have reached the end of the videos so stop everything
        this.stopPlayback = false
        this.stopProgressUpdate()
        if (this.isAudioSetup) {
          rampVolume(0, 1200).then(() => {
            audio.pause()
            audio.currentTime = 0
          })
        } else {
          audio.pause()
        }
        this.showPlayButton = true
        this.$emit('ended')
        return
      } else if (nextIndex == 0) {
        this.stopPlayback = true
      }

       // play video
       // !IMPORTANT: this assumes ios safari is going to fail, causing playbutton to appear and then allowing audiosetup to occur in a user click event handler
      try {
        await front.play()
        bg_video_cover_blur.play()
        audio.play()
      } catch(err) {
        audio.pause()
        this.showPlayButton = true
        if (/NotSupportedError/i.test(err)) {
          this.transcodeCurrentVideo()
        }
      }
    },
    transcodeCurrentVideo: function () {
      this.$toast.error("Video format is not compatible with this browser. Wait a few minutes while we update the video file. Or try another browser.", {timeout: 8000})
      this.$apollo.mutate({
        mutation: TRANSCODE_VIDEO_MUTATION,
        variables: {
          videoId: this.playProject.videos[this.currentPlaybackIndex].id
        },
      }).then((data) => {
        console.log(data)
      }).catch((error) => {
        console.error(error)
      })
    },
    openFullscreen: function(elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen()
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen()
      }
    },
    setIsSmallScreen: function(isSmall) {
      this.isSmallScreen = isSmall
    },
    stopProgressUpdate: function () {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    },
    startProgressUpdate: function() {
      this.stopProgressUpdate()
      this.timer = setInterval(() => {
        const video1 = document.getElementById("play1")
        const video2 = document.getElementById("play2")

        const playing = (this.currentPlaybackIndex%2 == 1) ? video2 : video1
        const progress = Math.round(playing.currentTime/playing.duration*100)
        // const timeLeft = playing.duration - playing.currentTime
        this.progress = isNaN(progress) ? 0 : progress
      }, 10)
    },
  },
  beforeUnmount: function () {
    this.stopProgressUpdate()
  },
  beforeRouteUpdate (to, from, next) {
    // react to route changes...
    // don't forget to call next()
    next()
  },
  mounted: function() {
    window.vue = this

    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/play_project'})
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.play_project {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.embed-container {
  --video--width: 1280;
  --video--height: 720;

  position: relative;
  padding-bottom: calc(var(--video--height) / var(--video--width) * 100%);
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index:100;
  top: 0px;
  left: 0px;
}

.embed-container iframe,
.embed-container object,
.embed-container embed,
.embed-container video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.embed-container video#video_cover_blur {
  -o-filter: blur(15px);
  filter: blur(15px);
  object-fit: cover;
  transform: scale(1.06); /* scale up to hide the edge blur */
  z-index: 7;
}

.blur_video {
  -o-filter: blur(15px);
  filter: blur(15px);
  object-fit: cover;
  transform: scale(1.06); /* scale up to hide the edge blur */
}

.video_cover {
  object-fit: cover;
}

.black_background {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #000000;
  z-index: 5;
}
#title {
  position: fixed;
  bottom: 20px;
  z-index: 200;
  color: #ffffff;
  font-size: 30px;
  font-weight: normal;
  width: 96%;
  padding: 20px 2%;
}

.title_smaller{
  font-size: 24px;
}

.play_button {
  position: fixed;
  z-index: 2000;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  background-color: rgb(0,0,0,0.55);
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.play_button:hover {
  background-color: rgb(0,0,0,0.35);
}
.play_button img {
  margin-left:8px;
  width: 28px;
  height: 32px;
}
</style>
