<template lang="pug">
div.base-timer
  svg.base-timer.svg(
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
  )
    g.base-timer.circle
      circle.base-timer.path-elapsed(
        cx="50"
        cy="50"
        r="46.5"
      )
      path.base-timer.path-remaining(
          d=`
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          `
          :stroke-dasharray="circleDasharray"
      )
  span.base-timer.label {{ formattedTimeLeft }}
</template>

<script>


export default {
  name: 'CountdownTimer',
  props: {
    startTime: {
      type: Number,
      required: true,
    },
    run: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    formattedTimeLeft() {
      const timeLeft = this.timeLeft
      // The largest round integer less than or equal to the result of time divided being by 60.
      // const minutes = Math.floor(timeLeft / 60)
      // Seconds are the remainder of the time divided by 60 (modulus operator)
      let seconds = timeLeft % 60
      // If the value of seconds is less than 10, then display seconds with a leading zero
      if (seconds < 10) {
        seconds = `0${seconds}`
      }
      // The output in MM:SS format
      // return `${minutes}:${seconds}`
      return `${seconds}`
    },
    circleDasharray() {
      return `${(this.timeFraction * 283).toFixed(0)} 283`;
    },
    timeFraction() {
      // Divides time left by the defined time limit.
      return (this.timeLeft) / this.startTime;
    },
  },
  watch: {
    timeLeft: function () {
      if (this.timeLeft <= 0) { this.timerEnd() }
    },
    run: function () {
      if (this.run) {
        this.startTimer()
      } else {
        this.clearTimer()
      }
    }
  },
  data() {
    return {
      timerInterval: null,
      timeLeft: this.startTime,
    }
  },
  methods: {
    clearTimer () {
      clearInterval(this.timerInterval)
        this.timerInterval = null
        this.timeLeft = this.startTime
    },
    timerEnd () {
        clearInterval(this.timerInterval)
        this.timerInterval = null
        this.timeLeft = 0
        this.$emit('timerEnd')
    },
    startTimer() {
      this.timeLeft = this.startTime
      this.timerInterval = setInterval(() => (this.timeLeft -= 1), 1000);
    },
  },
  mounted: function () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Sets the containers height and width */
.base-timer {
  pointer-events: none;
  position: relative;
  width: 80px;
  height: 80px;
}
/* Removes SVG styling that would hide the time label */
.circle {
  fill: none;
  stroke: none;
}
/* The SVG path that displays the timer's progress */
.path-elapsed {
  stroke-width: 7px;
  stroke: #ffffff;
}

.label {
  position: absolute;

  /* Size should match the parent container */
  width: 80px;
  height: 80px;
  /* Keep the label aligned to the top */
  top: 0;
  /* Create a flexible box that centers content vertically and horizontally */
  display: none; /*flex;*/
  align-items: center;
  justify-content: center;
  /* Sort of an arbitrary number; adjust to your liking */
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
}

.path-remaining {
  /* Just as thick as the original ring */
  stroke-width: 10px;
  /* Rounds the line endings to create a seamless circle */
  stroke-linecap: round;
  /* Makes sure the animation starts at the top of the circle */
  transform: rotate(90deg);
  transform-origin: center;
  /* One second aligns with the speed of the countdown timer */
  transition: 1s linear all;
  /* Allows the ring to change color when the color value updates */
  stroke: RGBA(240, 63, 31, 1.00);
}

.svg {
  /* Flips the svg and makes the animation to move left-to-right */
  transform: scaleX(-1);
}

</style>
