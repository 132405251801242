<template lang="pug">
div.view_container
  Header
  AddParticipantVideo(
    v-if="!isSafari && !isIOS"
    v-bind:slug="slug"
    v-bind:participant="currentParticipant"
  )
  AddParticipantVideoTwilio(
    v-if="isSafari && !isIOS"
    v-bind:slug="slug"
    v-bind:participant="currentParticipant"
  )
  AddParticipantVideoWithFileUpload(
    v-if="isIOS"
    v-bind:slug="slug"
    v-bind:participant="currentParticipant"
  )
</template>

<script>
/* global gtag */
import config from '../appConfig'
import AddParticipantVideo from '../components/AddParticipantVideo'
import AddParticipantVideoTwilio from '../components/AddParticipantVideoTwilio'
import AddParticipantVideoWithFileUpload from '../components/AddParticipantVideoWithFileUpload'
import { GET_PARTICIPANT_FROM_SLUG_QUERY } from '../graphql/queries'
import Header from '../components/Header'

export default {
  name: 'AddParticipantVideoContainer',
  props: {
    slug: String
  },
  components: {
    AddParticipantVideo,
    AddParticipantVideoTwilio,
    AddParticipantVideoWithFileUpload,
    Header,
  },
  data () {
    return {
      isIOS: false,
      isSafari: false,
      currentParticipant: this.participant,
    }
  },
  apollo: {

  },
  methods: {
    getParticipant: function() {
      this.$apollo.query({
        query: GET_PARTICIPANT_FROM_SLUG_QUERY,
        variables: {
          slug: this.slug
        }
      }).then((data) => {
        // console.log(data.data)
        this.currentParticipant = data.data.participant
        this.$store.commit('setParticipantSlug', this.slug)
        this.$store.commit('setProject', this.currentParticipant.project)
      }).catch((error) => {
        console.error(error)
      })
    },
  },
  beforeMount: function () {
    this.isSafari = config.isSafari
    this.isIOS = config.isIOS
  },
  mounted: function() {
    // window.vue = this
    if (!this.participant) { this.getParticipant() }

    if (this.isSafari && !this.isIOS) {
      this.$toast.warning(
        "Video recording on safari is very slow, we recommend chrome:  https://www.google.com/chrome",
        {timeout: false}
      )
    }
    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/add_participant_video'})
  },
}
</script>

<style>

#nav {
  display:none;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
