<template lang="pug">
div.segmented_progress
  span(v-if="numSegments > 0"
      v-for="n in numSegments"
      v-on:click="$emit('tapped',n)"
    )
    progress(
      max="100"
      :value="getProgress(n)"
      color="#ffffff"
      bg-color="#1C2036"
      :show-text="false"
      status="success"
      :line-height="5"
      v-bind:style="{width: progressWidth + '%'}"
      :color-flow="false"
      type="line"
    )
</template>

<script>


export default {
  name: 'SegmentedProgress',
  props: {
    numSegments: {
      type: Number,
      required: true,
    },
    progress: {
      type: Number,
      required: true,
    },
    currentSegment: {
      type: Number,
      required: true
    }
  },
  computed: {
    progressWidth () {
      const marginRightWidth = 0.5*this.numSegments
      return (100-marginRightWidth)/this.numSegments
    },
  },
  data() {
    return {

    }
  },
  methods: {
    getProgress: function(segmentN) {
      if (segmentN < this.currentSegment) return 100
      if (segmentN > this.currentSegment) return 0
      return this.progress
    },
  },
  mounted: function () {
  }
}
</script>

<style>
progress {
  display: inline-block;
  margin-right: 0.25%;
  margin-left: 0.25%;
  cursor: pointer;
  opacity: 0.5;
  height: 6px;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

progress[value]::-webkit-progress-bar {
  background-color: #1C2036;

  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

progress[value]::-webkit-progress-value{
  background-color: #ffffff;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

progress:hover {
  opacity: 1.0;
}

progress[value] {
  /* Reset the default appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  border: none;
}

progress[value]::-moz-progress-bar {
  background-color: #1C2036;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.segmented_progress {
  position: fixed;
  z-index: 2000;
  width: 100%;
  bottom: 10px;
}

</style>
