<template lang="pug">
div.invited
  div.view_container
    Header
  div.play_button(v-show="showPlayButton" v-on:click="play")
    img(:src="playIconSrc")
  div.control_overlay
    button.cta(v-on:click="next" v-show="showNext" style="touch-action: manipulation")
      img(:src="recordIconSrc")
      span Record my video
    button#toggle_mute(v-show="showMute"  style="touch-action: manipulation" v-on:click="toggleMute")
        span {{ toggleMuteButtonTitle }}
  div.embed-container
    video#intro_video(
      v-on:click="play"
      v-bind:class="{blur_video: showPlayButton}"
      width="100%"
      height="100%"
      playsinline
    )
    video#video_cover_blur(playsinline muted)
</template>

<script>
/* global gtag */
import { GET_PARTICIPANT_FROM_SLUG_QUERY } from '../graphql/queries'
import Header from '../components/Header'
// import config from '../appConfig'

export default {
  name: 'InvitedParticipant',
  components: {
    Header,
  },
  watch: {
    $route () {
      // react to route changes...
      if (this.$route.params.slug) {
        this.slug = this.$route.params.slug
      }
    },
    participant: function() {
      if (this.participant && !this.participant.project.introVideoUrl) {
        this.next()
      } else {
        this.play()
      }
    },
  },
  computed: {
    recordIconSrc () {
      return process.env.BASE_URL + "video_record_icon_white.svg"
    },
    playIconSrc () {
      return process.env.BASE_URL + "play_icon_white.svg"
    },
    toggleMuteButtonTitle () {
      return this.videoMuted ? "Unmute" : "Mute"
    },
    showMute () {
      return false // config.isSafari
    },
  },
  data () {
    return {
      participant: null,
      slug: null,
      showNext: false,
      videoMuted: false,
      showPlayButton: false,
    }
  },
  apollo: {
    participant () {
      return {
        // gql query
        query: GET_PARTICIPANT_FROM_SLUG_QUERY,
        // Static parameters
        variables () {
          return {
            slug: this.slug
          }
        },
        skip () {
          return !this.slug
        },
        fetchPolicy: 'cache-and-network',
      }
    }
  },
  methods: {
    play: async function() {
      var video = document.getElementById("intro_video")
      const bg_video_cover_blur = document.getElementById("video_cover_blur")

      video.src = this.participant.project.introVideoUrl
      bg_video_cover_blur.src = video.src

      video.load()
      bg_video_cover_blur.load()
      video.muted = false
      bg_video_cover_blur.muted = true

      // cover video if its in landscape
      video.style.objectFit = ((video.videoWidth < video.videoHeight) && (window.innerWidth > window.innerHeight)) ? "contain" : "cover"
      video.onloadeddata = () => {
        video.style.objectFit = ((video.videoWidth < video.videoHeight) && (window.innerWidth > window.innerHeight)) ? "contain" : "cover"
      }

      try {
        await video.play()
        bg_video_cover_blur.play()
        this.showPlayButton = false
        this.showNext = true
      } catch(err) {
        video.pause()
        bg_video_cover_blur.pause()
        video.currentTime = 0
        bg_video_cover_blur.currentTime = 0
        this.showPlayButton = true
      }

      video.addEventListener('ended', () => {
        // this.showNext = true
        this.next()
      })
    },
    toggleMute: function() {
      var video = document.getElementById("intro_video")
      video.muted = !video.muted
      this.videoMuted = video.muted
    },
    openFullscreen: function(elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen()
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen()
      }
    },
    next: function() {
      this.$router.push(
        {name:"AddParticipantVideoContainer",
        params: {
          participant: this.participant,
          slug: this.slug
        }
      })
    },
  },
  beforeRouteUpdate (to, from, next) {
    // react to route changes...
    // don't forget to call next()
    next()
  },
  beforeMount: function () {

  },
  mounted: function() {
    if (this.$route.params.slug) {
      this.slug = this.$route.params.slug
    }
    // window.vue = this

    // this.play()

    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/invited'})
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.invited {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.video_overlay {
  position: absolute;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color:rgb(0,0,0,0.65);
}

button img {
  display: inline-block;
  margin: 0px 14px 2px -14px;
}

.blur_video {
  -o-filter: blur(15px);
  filter: blur(15px);
  object-fit: cover;
  transform: scale(1.06); /* scale up to hide the edge blur */
}

.embed-container {
  --video--width: 1280;
  --video--height: 720;

  position: relative;
  padding-bottom: calc(var(--video--height) / var(--video--width) * 100%);
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index:100;
  top: 0px;
  left: 0px;
}

video#video_cover_blur {
  -o-filter: blur(15px);
  filter: blur(15px);
  object-fit: cover;
  transform: scale(1.06); /* scale up to hide the edge blur */
  z-index: 7;
}

#intro_video {
  z-index: 200;
}


.embed-container iframe,
.embed-container object,
.embed-container embed,
.embed-container video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.control_overlay {
  position: absolute;
  bottom: 10%;
  left: 0px;
  width: 100%;
  text-align: center;
  z-index: 1000;
}

.play_button {
  position: fixed;
  z-index: 2000;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  background-color: rgb(0,0,0,0.55);
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.play_button:hover {
  background-color: rgb(0,0,0,0.35);
}
.play_button img {
  margin-left:8px;
  width: 28px;
  height: 32px;
}
</style>
