<template lang="pug">
div.video_record.fill
  div#ask_title
    span.asked_by {{ askedByText }}
    br
    span {{ currentAskText }}
  div#countdown_container(v-show="showTimer")
    CountdownTimer(
      :startTime="recordingLimit"
      v-bind:run="runTimer"
      v-on:timerEnd="timerEnd"
    )
  div.video_controls
    div.loading(v-show="loading")
      img(v-bind:src="loadingSrc")
    div.wrapper(v-show="!loading")
      img.start(v-on:click="startRecording" ref="start" style="touch-action: manipulation" :src="recordStartButtonSrc" :title="recordButtonTitle")
      img.start(v-show="hasRecording && !isPlaying" v-on:click="playLocalFile" ref="play" style="touch-action: manipulation" :src="playButtonSrc")
      div.stop_recording_container(v-show="hasRecording && isPlaying" v-on:click="stopRecording" ref="stop" style="touch-action: manipulation")
        div#countdown_container(v-show="showTimer")
          CountdownTimer(
            :startTime="recordingLimit"
            v-bind:run="runTimer"
            v-on:timerEnd="timerEnd"
          )
        img.stop(:src="stopButtonSrc" :title="stopButtonTitle")
      img.next(v-show="hasRecording" v-on:click="saveRecording" ref="save" style="touch-action: manipulation" :src="nextButtonSrc" :title="nextButtonTitle")
      div.file_upload(v-show="!loading")
        input#file_input(type="file" v-on:change="fileUpload" ref="file" style="display:none" accept="video/mp4,video/x-m4v,video/*" capture="user")
      div.link_skip
        a(v-show="hasVideo"  style="touch-action: manipulation" v-on:click="nextAsk") Skip
  video.playback(
        autoplay
        playsinline
        loop
        muted
        )
  video#preview(
        autoplay
        playsinline
        loop
        muted
        )

</template>

<script>

import { ADD_PARTICIPANT_VIDEO_MUTATION, REMOVE_PARTICIPANT_VIDEO_MUTATION } from '../graphql/mutations'
import { GET_PARTICIPANT_FROM_SLUG_QUERY } from '../graphql/queries'
import CountdownTimer from '../components/CountdownTimer'
import config from '../appConfig'

export default {
  name: 'AddParticipantVideoWithFileUpload',
  props: {
    participant: Object,
    slug: String
  },
  components: {
    CountdownTimer,
  },
  computed: {
    recordButtonTitle () {
      if (this.hasRecording) { return "Re-Record" }
      return "Record"
    },
    stopButtonTitle () {
      return "Stop playback"
    },
    nextButtonTitle () {
      return "Save recording and go to next step"
    },
    nextButtonSrc () {
      return process.env.BASE_URL + "video_save_button.svg"
    },
    recordStartButtonSrc () {
      return process.env.BASE_URL + "record_start_button.svg"
    },
    playButtonSrc () {
      return process.env.BASE_URL + "play_button.svg"
    },
    stopButtonSrc () {
      return process.env.BASE_URL + "playback_stop_button.svg"
    },
    muteButtonSrc () {
      return this.videoMuted ? process.env.BASE_URL + "mute_off_button.svg" : process.env.BASE_URL + "mute_on_button.svg"
    },
    muteButtonTitle () {
      return this.videoMuted ? "Unmute" : "Mute"
    },
    toggleMuteButtonTitle () {
      return this.videoMuted ? "Unmute" : "Mute"
    },
    showSelectDevices () {
      return !config.isSafari
    },
    loadingSrc () {
      return process.env.BASE_URL + "loading.gif"
    },
    recordingLimit () {
      return config.recordLimitInSecs
    },
    currentProject () {
      return this.currentParticipant.project
    },
    currentUser () {
      return this.$store.state.user.user
    },
    projectAsks () {
      return this.currentParticipant.project.asks
    },
    introVideoUrl () {
      return this.currentParticipant.project.introVideoUrl
    },
    askCount () {
      return this.projectAsks.length
    },
    currentAsk () {
      return this.projectAsks[this.currentAskIndex]
    },
    currentAskText () {
      if (!this.currentParticipant) return ""
      return this.currentAsk.text
    },
    askedByText () {
      if (!this.currentParticipant) return ""
      return this.currentParticipant.project.user.firstName + " asks..."
    },
    hasVideo () {
      if (!this.currentParticipant?.videos || this.currentParticipant?.videos.length <= 0 || !this.currentAsk) return false

      return this.currentParticipant?.videos.find((video) => {
        return parseInt(video.askId) == parseInt(this.currentAsk.id)
      }) != undefined
    },
  },
  watch: {
  },
  data () {
    return {
      hasRecording: false,
      isPlaying: false,
      videoMuted: true,
      showTimer: false,
      runTimer: false,
      loading: false,
      currentAskIndex: 0,
      currentParticipant: this.participant,
      lastVideoId: null,
      videoUrl: null,
      file: null,
    }
  },
  apollo: {

  },
  methods: {
    toggleMute: function() {
      var video = document.querySelector('video.playback')
      video.muted = !video.muted
      this.videoMuted = video.muted
    },
    timerEnd: function () {
      this.stopRecording()
    },
    getParticipant: function() {
      this.$apollo.query({
        query: GET_PARTICIPANT_FROM_SLUG_QUERY,
        variables: {
          slug: this.slug
        }
      }).then((data) => {
        console.log(data.data)
        this.currentParticipant = data.data.participant
        // this.videoUrl = this.currentParticipant.project.introVideoUrl
        // this.playRecording()
      }).catch((error) => {
        console.error(error)
      })
    },
    saveRecording: function() {
      if (this.hasRecording) {
        this.hasRecording = false
        this.lastVideoId = null

        this.stopStreaming()
        this.resetPlayback()
        this.nextAsk()
        this.setupVideoPreview()
      }
    },
    loadLocalFile: function () {
      this.stopStreaming()
      this.resetPlayback()

      if (!this.file) return
      const video = document.querySelector("video.playback")
      video.loop = true
      video.autoplay = true
      video.muted = config.isSafari
      video.srcObject = null
      video.src = window.webkitURL.createObjectURL(this.file)
      video.load()
      video.pause()
    },
    playLocalFile: function() {
      const video = document.querySelector("video.playback")
      video.play().then(()=>{
        this.isPlaying = true
      }).catch((error) =>{
        console.log('play local file error: '+error)
      })

    },
    playRecording: function() {
      this.stopStreaming()
      this.resetPlayback()

      var video = document.querySelector("video.playback")
      video.loop = true
      video.autoplay = true
      video.muted = config.isSafari  // !IMPORTANT: cannot autoplay unmuted video on safari.  It needs a user interaction.
      this.videoMuted = video.muted
      video.src = this.videoUrl
      video.load()

      const playPromise = video.play()
      if (playPromise !== undefined) {
        playPromise.then(() => {
          // Automatic playback started!
        }).catch((error) => {
          // Automatic playback failed.
          // Show a UI element to let the user manually start playback.
          console.error(error)
        })
      }
    },
    stopPlayback: function() {
      var video = document.querySelector("video.playback")
      video.pause()
      this.isPlaying = false
    },
    stopRecording: function() {
      if (this.hasRecording) {
        this.stopPlayback()
      }
    },
    resetPlayback: function() {
      var video = document.querySelector("video.playback")
      if (video.src) {
        window.URL.revokeObjectURL(video.src)
        video.removeAttribute('src')
        video.load()
      }
    },
    startRecording: function() {
      this.stopStreaming()
      this.resetPlayback()
      this.setupVideoPreview()

      this.hasRecording = false
      this.$refs.file.click()
    },
    removeVideo: function (videoId) {
      this.$apollo.mutate({
        mutation: REMOVE_PARTICIPANT_VIDEO_MUTATION,
        variables: {
          slug: this.slug,
          videoId: videoId,
        },
      }).then((data) => {
        console.log(data)
      }).catch((error) => {
        console.error(error)
      })
    },
    fileUpload: function ({ target: { files = [] } }) {
      if (!files.length) {
        return
      }

      this.file = files[0]

      this.loading = true
      this.$apollo.mutate({
        mutation: ADD_PARTICIPANT_VIDEO_MUTATION,
        variables: {
          slug: this.slug,
          askId: this.currentAsk.id,
          file: this.file
        },
        context: {
          hasUpload: true
        }
      }).then((data) => {
        console.log(data)
        if (this.lastVideoId != null) { this.removeVideo(this.lastVideoId) }

        this.loading = false
        this.lastVideoId = data.data.addParticipantVideo.id
        this.videoUrl = data.data.addParticipantVideo.videoUrl
        this.hasRecording = this.videoUrl ? true : false
        // this.playRecording()
        this.loadLocalFile()
        // this.nextAsk()
      }).catch((error) => {
        console.error(error)
        this.loading = false
      })
    },
    stopStreaming: function() {
      var video = document.querySelector("video#preview")
      const stream = video.srcObject

      if (stream == null) return

      const tracks = stream.getTracks()

      tracks.forEach(function(track) {
        track.stop()
      })

      video.srcObject = null
    },
    setupVideoPreview: async function() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(config.mediaDeviceConstraints);
        var video = document.querySelector("video#preview")
        video.muted = true // otherwise audio feedback will occur
        video.srcObject = stream
        // window.stream = stream  // make stream available to console
        // console.log("getUserMedia() got stream:", stream)
      } catch (e) {
        console.error('navigator.mediaDevices.getUserMedia error:', e)
        if (/Permission denied/i.test(e)) {
          this.$toast.error("You have blocked this page from accessing your camera and microphone.  In the address bar, look for the icon that shows site permissions, and click to approve camera and microphone access and reload the page.",{timeout: false})
        }
      }

    },
    openFullscreen: function(selector) {
      var elem = document.querySelector(selector)

      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen()
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen()
      }
    },
    nextAsk: function() {
      if (this.currentAskIndex >= this.askCount - 1) {
        this.next()
      } else {
        this.currentAskIndex = this.currentAskIndex + 1
      }
    },
    next: function() {
      this.$router.push({name:"Done"})
    },
  },
  mounted: function () {
    window.vue = this
    // console.log(this.slug)
    // console.log(this.participant)
    if (!this.participant) { this.getParticipant() }
    this.setupVideoPreview()
  }
}
</script>

<style scoped>
.video_record {
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  overflow: hidden;
}


.overlay {
  display: block;
  position: absolute;
}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

video#preview {
  z-index: 100;
}

video.playback {
  z-index: 200;
}

.asked_by {
  font-size: 24px;
  font-weight: normal;
}
#ask_title {
  font-weight: bold;
  font-size: 40px;
  color: #ffffff;
  text-align: center;
  width: 100%;
  position: relative;
  margin-top: 20%;
  z-index: 1000;
  text-shadow: 4px 4px 6px rgba(0,0,0,0.30);

}

.title {
  position: absolute;
  z-index: 500;
  top: 45%;
  width: 100%;
  color: #ffffff;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  text-shadow: 0 2px 40px rgba(0,0,0,0.45);
}

.select_input_device_container {
  position: absolute;
  z-index: 800;
  bottom: 30%;
  width: 260px;
  border: none;
  text-align: left;
}

.select_input_device {
  border: 2px solid rgba(255,255,255,0.8);
  border-radius: 10px;
  padding: 8px 12px;
  cursor: pointer;
  color: rgba(255,255,255,0.8);
  font-size: 16px;
  font-weight: bold;
  text-shadow: 0 2px 10px rgba(0,0,0,0.50);
  box-shadow: 0 2px 10px rgba(0,0,0,0.20);
  margin-bottom: 10px;
}

.file_upload {
  display: inline-block;
}
.file_upload a {
  padding: 4px 10px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  color: #ffffff;
}


#countdown_container {
  position: absolute;
  z-index: 800;
  top: 0px;
  left: 9px;
  width: 80px;
  height: 80px;
}

.settings {
  cursor: pointer;
  display: block;
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 30%;
  left: 2%;
}

.stop_recording_container {
  display: inline-block;
  position: relative;
}

.link_skip {
  position: absolute;
  bottom: -25px;
  width: 100%;
  text-align: center;
}

</style>
