<template lang="pug">
div.view_container(v-bind:class="{view_container_small: isSmallScreen}")
  Header(v-on:is-small-screen="setIsSmallScreen")
  div.view_title
    span Apart, But Together
    br
    span.subtitle Let's make more meaningful messages
    br
  div.description
    span 2020 was an upside down year. The isolation of social distancing was one of the hardest things for us, but, really, this has been happening for years. COVID just accelerated it. I'm not talking physical social distance; I'm talking about the transformation of friendships to phone-ships, where our social interactions increasingly became social media interactions: liking, commenting, and posting.
    br
    br
    span This gravity well of numbingly easy, bite-sized interactions made me question: &nbsp;&nbsp;
    span(style="font-style:italic") Do I have any real friends... people for whom I matter and vice versa?
    br
    br
    span  The truth of it is: yes I do, and, yes you do. There's just not many occasions where it’s easy for someone to say thanks for being in my life.
    br
    br
    span What if it were easier? Sometimes, social media is just not the right medium to encourage this kind of communication. It’s literally paid to promote content that entertains which tends to be content meant for everyone. But, more often than not, genuine, authentic, and meaningful content matters to only a handful of people.
    br
    br
    span  I want to change that, because I think we can be better together.
    br
    br
    span I started WishWell using my experiences inviting friends to make group videos for the people we care about. I've seen how powerful receiving a video of messages from friends can be when the message is personal, genuine, and filled with meaning.  But, I also saw how much time, effort, and skill was needed to produce those videos and how inaccessible it was for most people.
    br
    br
    span WishWell builds on those experiences and learnings to make it as simple and easy to create shared video experiences as it would be to post on social media. I hope you're able to feel some of what we're looking to accomplish in this initial launch.
    br
    br
    span The circle of people who care about you is bigger than you think.  The circle of lives you've touched is bigger than you know. And I want to help you realize that you matter to a lot of people.
    br
    br
    span We're just getting started, so if you feel this itch too, give me a&nbsp;
    a(href="mailto:ben@wishwell.to" style="text-decoration:underline;") shout
    span .
  div.bio
    img(src="https://wishwell-site-content.s3.amazonaws.com/ben_w_dog_profile.jpg")
    div.bio_info
      div.name Ben Chow
      div.title founder
      div.description
        span product designer & serial entrepreneur, ex-
        a(href="https://www.ideo.com/") @IDEO
        span , cofounder/COO&nbsp;
        a(href="https://www.friended.com/") @Friended
        span , CPO&nbsp;
        a(href="https://www.checkedup.com/") @CheckedUp
        span , Head of Product at @Cirle
        span , early social games pioneer @Hive7 (funded by True Ventures, acquired by Disney/Playdom).
    div.clear
</template>

<script>
/* global gtag */

import config from '../appConfig'
import Header from '../components/Header'

export default {
  name: 'About',
  props: {
  },
  components: {
    Header,
  },
  data() {
    return {
      isSmallScreen: config.isSmallScreen(),
    }
  },
  apollo: {

  },
  methods: {
    next: function(route) {
      this.$router.push(route)
    },
    setIsSmallScreen: function(isSmall) {
      this.isSmallScreen = isSmall
    }
  },
  mounted: function () {
    // window.vue = this
    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/about'})
  }
}
</script>

<style scoped>
  .view_container {
    font-family: gibsonregular;
    font-size: 26px;
  }


  .subtitle {
    font-family: gibsonregular;
    font-size: 26px;
  }

  .description {
    font-family: 'Times New Roman', Times, serif;
    font-size: 22px;
    color: #5a5d61;
  }

  .bio {
    margin: 60px 0;
    padding: 30px 30px;
    background-color: #f6f7f8;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    float: left;
  }
  .bio img {
    width: 48px;
    height: 48px;
    display: block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    overflow: hidden;
    float: left;
    margin: 0px 12px 12px 0px;
  }

  .bio_info {
    float: left;
    max-width: 450px;
  }

  .bio_info .name {
    font-family: gibsonsemibold;
    font-size: 20px;
  }

  .bio_info .title {
    font-family: gibsonregular;
    font-size: 18px;
  }

  .bio_info .description {
    font-family: gibsonregular;
    font-size: 18px;
    color: RGBA(148, 154, 172, 1.00);
  }
  .description a {
    color: RGBA(148, 154, 172, 1.00);
  }
</style>